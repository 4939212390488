<template>
  <div class="container-bg-r">
    <NavBar :navSelIndex="7"></NavBar>
    <b-container class="container-t-mode container-t-mode-wrap container-bg-w" fluid>
      
      <b-row class="category-title-first-box">
        
        <div :class="activeIndex == index ? 'category-title-first-t active' : 'category-title-first-t'" v-for="(item, index) in videoType" :key="index" @click="videoTypeChange(index, item)">
          <div :class="activeIndex == index ? 'category-title-first active' : 'category-title-first'">{{item.filed}}</div>
        </div>
      </b-row>
     
    </b-container>
    <b-row class="margin-botton-48"></b-row>
    <b-container fluid class="video-con">
      <b-row>
        <b-col sm="6" md="4" lg="3" xl="2" v-for="(item, index) in videos" :key="index">
          <el-card :body-style="{ padding: '0px' }" shadow="hover">
            <!-- <video controls :poster="item.videoCover" ref="video" :data-index="index">
              <source :src="item.videoPath" type="video/mp4">
            </video> -->
            <div class="pic" @click="toPlay(item)">
              <img :src="item.videoCover" :alt="item.videoTitle">
              <i class="el-icon-video-play"></i>
            </div>
            <div class="detail-wrap">
              <span class="video-name">{{item.videoTitle}}</span>
              <div class="bottom clearfix">
                <!-- <el-button type="text" class="button" @click="videoPlay(index)">{{item.play ? "暂停" : "播放"}}</el-button> -->
                <el-button type="text" class="button" @click="toPlay(item)">播放</el-button>
              </div>
            </div>
          </el-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <el-pagination
            background
            hide-on-single-page
            layout="pager"
            @current-change="pageSizeChange"
            :page-size="pageSize"
            :total="total">
          </el-pagination>
        </b-col>
      </b-row>
    </b-container>
    <Right></Right>
    <Footer></Footer>
  </div>
</template>

<script>
  import NavBar from "@/components/Navbar.vue";
  import Footer from "@/components/Footer.vue";
  import Right from "@/components/Right.vue";
  import dump from "@/util/dump";
  export default {
    components: {
      NavBar,
      Footer,
      Right,
    },
    data() {
      return {
        videoType: [],
        activeIndex: 0,
        videoTypeId: '',
        videos: [],
        pageNum: 1,
        pageSize: 24,
        total: 0
      };
    },
    created() {
      this.init();
    },
    // beforeUpdate() {
    //   this.monitor();
    // },
    methods: {
      init(){
        this.getVideoClassify();
        this.getVideos();
      },
      // 获取视频分类
      getVideoClassify(){
        this.$api.videoClassify({
          parentId: 3
        }).then(res => {
          if(res.status == 100){
            res.data.unshift({
              filed: "全部",
              filedIndex: ''
            })
            
            this.videoType = res.data;
          }
        }).catch(err => console.error(err))
      },
      // 获取视频
      getVideos(){
        this.$api.videos({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          vedioType: this.videoTypeId
        }).then(res => {
          if(res.status == 100){
            this.pageSize = res.data.pageSize;
            this.total = res.data.total;
            this.videos = res.data.list;
            this.$set(this.videos, 'play', false);
          }
        }).catch(err => console.error(err))
      },
      // 切换视频类型
      videoTypeChange(i, obj){
        this.activeIndex = i;
        this.videoTypeId = obj.filedIndex;
        this.getVideos();
      },
      // 跳转到视频播放页
      toPlay(obj){
        const params = {
          type: 3,
          link: "/videoTutorial/videoPlay",
          params: {
            videoId: obj.videoId,
            videoTypeId: obj.videoType
          }
        }
        dump.link(params);
      },
      // 播放/暂停
      // videoPlay(i){
      //   if(this.$refs.video[i].paused){
      //     this.$refs.video[i].play();
      //     this.$set(this.videos[i], 'play', true);
      //   }else{
      //     this.$refs.video[i].pause();
      //     this.$set(this.videos[i], 'play', false);
      //   }
      // },
      // 监听视频播放或暂停
      // monitor(){
      //   this.$nextTick(() => {
      //     this.$refs.video.forEach(item => {
      //       item.addEventListener('play', (e) => {
      //         this.$set(this.videos[e.srcElement.dataset.index], 'play', true);
      //       }, false)
      //       item.addEventListener('pause', (e) => {
      //         this.$set(this.videos[e.srcElement.dataset.index], 'play', false);
      //       }, false)
      //     })
      //   })
      // },
      // 分页切换
      pageSizeChange(num){
        this.pageNum = num;
        this.getVideos();
        this.$utils.scrollTop();
      }
    },
  };
</script>

<style lang="scss" scoped="scoped">
  @import "../../assets/scss/style.scss";

  .container-bg-r{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .container-bg-r {
    // background: rgba($color: #000000, $alpha: 0.1);
  }

  .container-bg-w {
    background: rgb(228, 221, 211);
    // background-image: url("../../../public/icon/store_bg_img.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
  }
  
  .video-con{
    flex: 1;
  }

  input:focus {
    outline: none;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .row-line {
    height: 0.06rem;
    background: #dcdcdc;
  }

  .margin-botton-48 {
    margin-bottom: 3rem;
  }

  .margin-20-0 {
    margin: 1.25rem 0;
  }

  .margin-top-80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .container-t-mode {
    
    .category-title-first-box {
      // margin-top: 2.875rem;
      padding-top: 2.875rem;

      .category-title-first-t {
        padding: 0.4375rem 1.375rem 0 1.375rem;
        background: rgba($color: #ffffff, $alpha: 0.16);
        border-radius: 0.5rem 0.5rem 0px 0px;
        margin-right: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .category-title-first {
          font-size: 0.8125rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: $font-title-default-color;
          // width: 2.5rem;
          padding-bottom: 0.4375rem;
        }

        .active {
          border-bottom: solid 0.0625rem;
          color: $bg-default-s-color;
        }
      }

      .active {
        background: rgba($color: #ffffff, $alpha: 1);
      }
    }
  }

  .container-t-mode-wrap {
    
    /deep/ .el-pagination {
      .el-pager {
        li:not(.disabled).active {
          background-color: $bg-default-s-color;
          color: #fff;
        }

        li:not(.disabled):hover {
          color: $bg-default-s-color;
        }

        li:not(.disabled).active:hover {
          color: #fff;
        }
      }
    }
    
  }
  
  .el-card{
    margin-bottom: 20px;
    img{
      width: 100%;
    }
    .pic{
      position: relative;
      cursor: pointer;
      .el-icon-video-play{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        font-weight: 400;
        font-size: 40px;
        color: #fff;
        z-index: 100;
      }
    }
    
    .detail-wrap{
      padding: 10px;
      text-align: left;
    }
    .video-name{
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      overflow: hidden;
    }
    .bottom .button{
      padding: 0;
      color: #727171;
    }
  }
  
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #f39110;
  }
  
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #f39110;
    color: #FFF;
  }
  
  

  @media (min-width: 1200px) {
    .container-t-mode-wrap {
      padding: 0 6.25rem;
    }
  }
</style>
